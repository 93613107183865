html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: 700;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625;
  letter-spacing: 0.00938em;
  background-color: #f0f2f5;
}

@media print {
  body {
    background-color: #fff;
  }
}

body::backdrop {
  background-color: #f0f2f5;
}

html {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

a,
a:link,
a:visited {
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
}

a.link,
.link,
a.link:link,
.link:link,
a.link:visited,
.link:visited {
  color: #344767 !important;
  -webkit-transition: color 150ms ease-in !important;
  transition: color 150ms ease-in !important;
}

a.link:hover,
.link:hover,
a.link:focus,
.link:focus {
  color: #1a73e8 !important;
}

.flatpickr-day:hover,
.flatpickr-day:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.nextMonthDay:focus {
  background: rgba(26, 115, 232, 0.28);
  border: none;
}

.flatpickr-day.today {
  background: #1a73e8;
  color: #ffffff;
  border: none;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  background: #1662c4 !important;
}

.flatpickr-day.selected,
.flatpickr-day.selected:hover,
.flatpickr-day.nextMonthDay.selected,
.flatpickr-day.nextMonthDay.selected:hover,
.flatpickr-day.nextMonthDay.selected:focus {
  background: #1a73e8 !important;
  color: #ffffff;
  border: none;
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  color: #1a73e8 !important;
  fill: #1a73e8 !important;
}

@media (min-width: 576px) {
  .MuiContainer-root {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100% !important;
    position: relative;
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .MuiContainer-root {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100% !important;
    position: relative;
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .MuiContainer-root {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100% !important;
    position: relative;
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .MuiContainer-root {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100% !important;
    position: relative;
    max-width: 1140px !important;
  }
}

@media (min-width: 1400px) {
  .MuiContainer-root {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100% !important;
    position: relative;
    max-width: 1320px !important;
  }
}

.css-1vjcy33 {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  padding: 0rem;
  margin-bottom: 0rem;
  border-radius: 0.375rem;
  font-weight: 600;
  margin-left: -16px;
}

.css-p4p9m5-MuiDialog-root {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  padding: 0rem;
  margin-bottom: 0rem;
  border-radius: 0.375rem;
  font-weight: 600;
  margin-left: -16px;
}

@media print {
  .css-p4p9m5-MuiDialog-root {
    position: absolute !important;
  }
}

.css-1czb4xw-MuiModal-root-MuiDialog-root {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  padding: 0rem;
  margin-bottom: 0rem;
  border-radius: 0.375rem;
  font-weight: 600;
  margin-left: -16px;
}

@media print {
  .css-1czb4xw-MuiModal-root-MuiDialog-root {
    position: absolute !important;
  }
}

.css-tkzpuq-MuiDialog-backdrop {
  z-index: -1;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop2 {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.125);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.css-1sep8xo-MuiDialog-container {
  height: 100%;
  outline: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media print {
  .css-1sep8xo-MuiDialog-container {
    height: auto;
  }
}

.css-xs2odt-MuiDialog-paper {
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: calc(100% - 64px);
  border-radius: 0.5rem;
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
}

@media print {
  .css-xs2odt-MuiDialog-paper {
    overflow-y: visible;
    box-shadow: none;
  }
}

.css-8hs8pd-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: calc(100% - 64px);
  border-radius: 0.5rem;
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
}

.css-8hs8pd-MuiPaper-root-MuiDialog-paper2 {
  background-color: rgba(255, 255, 255, 0.75);
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: calc(100% - 64px);
  border-radius: 0.5rem;
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
}

@media print {
  .css-8hs8pd-MuiPaper-root-MuiDialog-paper {
    overflow-y: visible;
    box-shadow: none;
  }
}

.css-hm4r8a-MuiDialogTitle-root {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.25rem;
  margin: 0px;
  padding: 16px;
}

.css-4mtlno-MuiTypography-root-MuiDialogTitle-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.625;
  color: #344767;
  font-weight: 700;
  letter-spacing: 0.0075em;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.25rem;
  margin: 0px;
  padding: 16px;
}

.css-x1l5l3-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: inherit !important;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 56px;
  height: 56px;
}

.css-79qiys {
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}

.css-1npqie7 {
  padding-left: 80px;
  margin-right: 80px;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}

.css-872vxe {
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #7b809a;
  letter-spacing: -0.125px;
  font-weight: 400;
}

.css-30f0a0-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.625;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #7b809a;
  letter-spacing: -0.125px;
  font-weight: 400;
}

.css-5bin0r {
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #344767;
  letter-spacing: -0.125px;
  font-weight: 700;
}

.css-12fe60l-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.625;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #344767;
  letter-spacing: -0.125px;
  font-weight: 700;
}

.css-9igssw-MuiDialogContent-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 20px 24px;
  padding: 1rem;
  font-size: 1rem;
  color: #7b809a;
}

.MuiDialogTitle-root + .css-9igssw-MuiDialogContent-root {
  padding-top: 0;
}

.css-wpo9qo-MuiDivider-root {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  background: rgba(52, 71, 103, 0.2);
  height: 0.0625rem;
  margin: 1rem 0;
  border-bottom: none;
  opacity: 0.25;
  margin-top: 8px;
  margin-bottom: 8px;
}

.css-ict9cr-MuiGrid-root {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -8px;
  width: calc(100% + 8px);
  margin-left: -8px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-ict9cr-MuiGrid-root > .MuiGrid-item {
  padding-top: 8px;
}

.css-ict9cr-MuiGrid-root > .MuiGrid-item {
  padding-left: 8px;
}

.css-1tdjyac-MuiGrid-root {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  text-align: center;
}

.css-1ircn5c {
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}

.css-1xtq3t7-MuiCard-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  overflow: visible;
  box-shadow: 0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.1),
    0rem 0.625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.04);
  padding: 8px;
}

.css-15fcxt6-MuiPaper-root-MuiCard-root {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  overflow: visible;
  box-shadow: 0rem 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.1),
    0rem 0.625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.04);
  padding: 8px;
}

.css-19m9tu5 {
  opacity: 1;
  color: #344767;
  border-radius: none;
  box-shadow: none;
  width: 46px;
  height: 31px;
  background: url(../public/images/flags-country.webp) 195px -204px;
}

.css-tt792h {
  opacity: 1;
  color: #344767;
  border-radius: none;
  box-shadow: none;
  width: 46px;
  height: 31px;
  background: url(../public/images/flags-country.webp) 195px -204px;
}

.css-if2lyn {
  opacity: 1;
  color: #344767;
  border-radius: none;
  box-shadow: none;
  width: 46px;
  height: 31px;
  background: url(../public/images/flags-country.webp) -50px -340px;
}

.css-i4dxxq {
  opacity: 1;
  color: #344767;
  border-radius: none;
  box-shadow: none;
  width: 46px;
  height: 31px;
  background: url(../public/images/flags-country.webp) -50px -340px;
}

.css-ang458-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #7b809a;
  letter-spacing: -0.125px;
  font-weight: 400;
}

.css-7r8d6e-MuiDialogActions-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 1rem;
}

.css-7r8d6e-MuiDialogActions-root > :not(:first-of-type) {
  margin-left: 8px;
}

.MuiGrid-hover:hover {
  cursor: pointer;
  background: rgb(248, 249, 250);
  box-shadow: 0rem 1.25rem 1.5625rem -0.3125rem rgb(154, 160, 166),
    0rem 0.625rem 0.625rem -0.3125rem rgb(154, 160, 166);
}

.css-9m7sc1 {
  max-width: 8.4rem;
  margin: 16px;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}

.css-a7zhuf {
  margin-left: 64px;
  margin-right: 64px;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}
